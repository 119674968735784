import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Grid, useMediaQuery, Box } from '@mui/material';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import {
  CardPaper, ProductImg, ImgBox, DetailsBox, HeaderContainer,
  DetailsContainer, ActionsContainer, PromoPriceText,
  HeaderText, TypoContainer, PackSizeTypo,
  PricingAndSaving, PricingWrapper,
  Pricing, ActionButton, AddButton,
  CircularProgressLoader, ActionTextField, InputButtonGroup,
  AddCartGrid, ShoppingCartImg, PromoContainer, PromoText,
  AddCartTypo, ProductVariantText, ProductVContainer, ChangeWrapper, MinusIcon, PlusIcon,
  NotificationImg, CardWrapper, SubscribeButton, ConfirmationButton,
  NotificationTypo, MarkIconImg, DivBullet, Divider, PackSizeTypoText,
  ManufacTypo
} from './productCard.styles';
import currencyFormatter from '../../../utils/currencyFormatter';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { UPDATE_ORDER_MUTATION } from '../../../mutations/orders';
import { useStateValue } from '../../../providers/stateProvider';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { NOTIFICATION_SUBSCRIPTION } from '../../../mutations/notifications';
import PromoDetailsPopper from './promoDetailsPopper';

const ProductCard = ({
  product, openAlert, cart: cartProduct, similarAction, index, refetch, refetchCart, active
}) => {
  const [{
    cart: { isManualOrder }, user: { platform },
    affiliate: { pricePolicyFactor = 1 }
  }, dispatch] = Object.values(useStateValue());
  const preferredPlatform = platform;
  const navigate = useNavigate();
  const productToUse = active === 'clearanceSale' ? product?.product : product;
  const {
    id, brandName, quantityInStock: productQTYINSTock, expiryDate,
    resolvedPriceInUseValue: actualPrice, packSize, image, manufacturer,
    promoEndDate, mktPrice, promoValue, promoCondition, priceDifferenceValue, priceDifferencePercentage, userSubscriptionStatus
  } = new Product(productToUse, preferredPlatform);

  const [counter, setCounter] = useState(0);
  const [countdown, setCountdown] = useState('');
  const quantityInStock = product?.quantityInStock || productQTYINSTock;
  const batchExpire = moment(product?.expiryDate || expiryDate).format('MM/YY');
  const expiredDate = batchExpire === 'Invalid date' ? 'N/A' : batchExpire;
  const [openDetails, setOpenDetails] = useState(false);

  const [updateOrder, { loading }] = useMutation(UPDATE_ORDER_MUTATION);
  const [subscribeToProductNotification] = useMutation(NOTIFICATION_SUBSCRIPTION);

  const handleActionButtons = (value) => {
    const sum = counter + value;
    if (sum > quantityInStock) return toast.error('Requested quantity not available');
    setCounter(sum);
  };
  const handleChange = (value) => {
    if (value > quantityInStock) return toast.error('Requested quantity not available');
    setCounter(value);
  };

  const itemPrice = (product?.price || actualPrice) * pricePolicyFactor;

  const promoSavings = +itemPrice - +mktPrice;
  const savings = currencyFormatter(priceDifferenceValue || promoSavings);
  const savingsPercent = promoValue || priceDifferencePercentage;
  const isSmall = useMediaQuery('(max-width: 991px)');
  const qtyCount = !quantityInStock;
  const promoStatus = promoCondition === 'ONGOING' && true;
  const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
  const selectedAffiliate = localStorage.getItem(ogaOrderingForAffiliateKey);
  const _selectedAffiliate = selectedAffiliate ? JSON.parse(selectedAffiliate) : null;

  const handleAddToCart = () => {
    updateOrder({
      variables: {
        productId: +id,
        quantity: counter,
        isManualOrder,
        affiliateId: _selectedAffiliate?.id,
        overwriteQuantity: true,
        ...(active === 'clearanceSale' && { batchId: product?.id }),
      }
    })
      .then(({ data }) => {
        const { message, totalProductCount } = data?.updateOrderProduct || {};
        dispatch({
          type: CartActionTypes.CART_COUNT,
          payload: totalProductCount
        });
        if (cartProduct) {
          refetchCart();
        }
        openAlert(message);
      });
  };

  const handleSubscription = () => {
    subscribeToProductNotification({
      variables: {
        productId: id
      }
    })
      .then(({ data }) => {
        const { message } = data?.subscribeToProductNotification || {};
        toast.success(message);
        refetch();
      });
  };

  const handleClick = () => {
    if (active === 'clearanceSale') {
      navigate(`/new-order/${product?.id}/clearance-sales-details`);
    } else {
      navigate(`/new-order/${id}/details`);
    }
  };
  const parsedDate = moment(promoEndDate).endOf('day');

  function formatDuration(duration) {
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${days}days - ${hours}hrs ${minutes}Mins ${seconds}Sec`;
  }

  if (promoStatus) {
    setInterval(() => {
      const currentDate = moment();
      const remainingTime = parsedDate.diff(currentDate);
      const timeCountdown = moment.duration(remainingTime);
      setCountdown(formatDuration(timeCountdown));
    }, 5000);
  }

  return (
    <CardPaper
      similarAction={similarAction}
      elevation={2}
      stock={qtyCount}
      spacing={3}
      cart={cartProduct}
      className={`mpFe-uat-new-order-card-${index}`}
      openDetails={openDetails}
    >
      <CardWrapper>
        <ImgBox onClick={handleClick}>
          {!isSmall && (
            active !== 'clearanceSale' ? (
              <Grid container xs={12}>
                <Grid item xs={6}>
                  {quantityInStock > 0
                    ? (
                      <ProductVContainer cart={cartProduct} status="available">
                        <DivBullet type="available" />
                        &nbsp;
                        <ProductVariantText status="available">Readily Available</ProductVariantText>
                      </ProductVContainer>
                    )
                    : (
                      <ProductVContainer cart={cartProduct} status="stockOut">
                        <DivBullet type="stockOut" />
                        &nbsp;
                        <ProductVariantText status="stockOut">Out of Stock</ProductVariantText>
                      </ProductVContainer>
                    )}
                </Grid>
                <Grid item xs={6}>
                  {quantityInStock <= 0 && (
                    <ProductVContainer cart={cartProduct} status="mutable" style={{ marginLeft: 'auto' }}>
                      <DivBullet type="mutable" />
                      &nbsp;
                      <ProductVariantText status="mutable">Mutable Price</ProductVariantText>
                    </ProductVContainer>
                  )}
                </Grid>
              </Grid>
            ) : (
              <ProductVContainer cart={cartProduct} status="clearanceSale">
                <DivBullet type="clearanceSale" />
                &nbsp;
                <ProductVariantText status="clearanceSale">Clearance Sale</ProductVariantText>
              </ProductVContainer>
            )
          )}
          <ProductImg component="img" cart={cartProduct} src={image} alt={brandName} loading="lazy" />
        </ImgBox>
        <DetailsBox {...(isSmall && { onClick: () => setOpenDetails(!openDetails) })}>
          <DetailsContainer>
            <Box>
              {isSmall && (
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={6}>
                    {quantityInStock > 0
                      ? (
                        <ProductVContainer cart={cartProduct} status="available">
                          <DivBullet type="available" />
                          &nbsp; &nbsp;
                          <ProductVariantText status="available">Readily Available</ProductVariantText>
                        </ProductVContainer>
                      )
                      : (
                        <ProductVContainer cart={cartProduct} status="stockOut">
                          <DivBullet type="stockOut" />
                          &nbsp; &nbsp;
                          <ProductVariantText status="stockOut">Out of Stock</ProductVariantText>
                        </ProductVContainer>
                      )}
                  </Grid>
                  <Grid item xs={6}>
                    {quantityInStock <= 0 && (
                      <ProductVContainer cart={cartProduct} status="mutable">
                        <DivBullet type="mutable" />
                        &nbsp;
                        <ProductVariantText status="mutable">Mutable Price</ProductVariantText>
                      </ProductVContainer>
                    )}
                  </Grid>
                </Grid>
              )}
              <HeaderContainer>
                <HeaderText variant="subtitle2">
                  {brandName}
                </HeaderText>
              </HeaderContainer>
              <TypoContainer item container xs={12}>
                <ChangeWrapper>
                  <ManufacTypo>
                    {manufacturer}
                  </ManufacTypo>
                </ChangeWrapper>
                <ChangeWrapper container item xs={12}>
                  <PackSizeTypoText
                    item
                    xs={12}
                    variant="body1"
                    similarAction={similarAction}
                  >
                    {packSize}
                    &nbsp; &nbsp;
                    <Divider>|</Divider>
                    &nbsp; &nbsp;
                    {quantityInStock}
                    {' '}
                    Qty in Stock
                  </PackSizeTypoText>
                </ChangeWrapper>
                <ChangeWrapper>
                  <PackSizeTypo>
                    Expiry Date:
                    &nbsp; &nbsp;
                    {expiredDate}
                  </PackSizeTypo>
                </ChangeWrapper>
                <PricingAndSaving item container justify-content="space-between">
                  <PricingWrapper item xs={6} md={6}>
                    <Pricing>
                      {currencyFormatter(Math.round(product?.discountedPrice) || mktPrice)}
                    </Pricing>
                  </PricingWrapper>
                  <Grid item xs={2} md={3}>
                    {(product?.discount || promoStatus) && (
                      <PromoContainer>
                        <PromoText>
                          {`${product?.discount || promoValue}%`}
                        </PromoText>
                      </PromoContainer>
                    )}
                  </Grid>
                  <Grid item container xs={4} md={3}>
                    {(product?.discount || promoStatus) && (
                      <PromoPriceText>
                        {currencyFormatter(itemPrice)}
                      </PromoPriceText>
                    )}
                  </Grid>
                </PricingAndSaving>
              </TypoContainer>
            </Box>
          </DetailsContainer>

          {!isSmall && (
            <PromoDetailsPopper
              countdown={countdown}
              savings={Math.round(product?.discountedPrice) || savings}
              savingsPercent={savingsPercent || product?.discount}
            />
          )}

        </DetailsBox>
      </CardWrapper>
      <ActionsContainer container spacing={isSmall ? 4 : ''}>
        <AddCartGrid item xs={5} lg={12}>
          <InputButtonGroup size="small" aria-label="small outlined button group">
            <ActionButton
              onClick={() => handleActionButtons(-1)}
              disabled={counter === 0 || quantityInStock <= 0}
              className={`mpFe-uat-new-order-minus-${index}`}
            >
              <MinusIcon />
            </ActionButton>
            <ActionTextField
              InputProps={{ disableUnderline: true, }}
              disabled={quantityInStock <= 0}
              variant="standard"
              placeholder={0}
              value={counter || ''}
              onChange={(e) => handleChange(Number(e.target.value))}
            />
            <ActionButton
              onClick={() => handleActionButtons(1)}
              className={`mpFe-uat-new-order-add-${index}`}
              disabled={quantityInStock <= 0}
            >
              <PlusIcon />
            </ActionButton>
          </InputButtonGroup>
        </AddCartGrid>
        <Grid container item xs={7} lg={12} spacing={2}>
          {quantityInStock > 0 ? (
            <AddCartGrid item xs={12}>
              <AddButton
                startIcon={<ShoppingCartImg counter />}
                variant="outlined"
                disableElevation
                onClick={handleAddToCart}
                disabled={counter === 0}
                counter={counter}
                className={`mpFe-uat-new-order-plus-${index}`}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={24}
                    thickness={4}
                  />
                )
                  : (
                    <AddCartTypo type={similarAction && 'similarAction'}>
                      &nbsp;
                      Add To Cart
                    </AddCartTypo>
                  )}
              </AddButton>
            </AddCartGrid>
          ) : (
            <AddCartGrid item xs={12}>
              {userSubscriptionStatus ? (
                <ConfirmationButton
                  startIcon={<MarkIconImg />}
                  variant="outlined"
                  disableElevation
                  counter={counter}
                  className={`mpFe-uat-new-order-subscription-${index}`}
                >
                  <NotificationTypo> Thanks! You will be notified when the product is back in stock </NotificationTypo>
                </ConfirmationButton>
              ) : (
                <SubscribeButton
                  startIcon={<NotificationImg />}
                  variant="outlined"
                  disableElevation
                  onClick={handleSubscription}
                  counter={counter}
                  className={`mpFe-uat-new-order-subscription-${index}`}
                >
                  <AddCartTypo type="notify"> Notify me on Availability </AddCartTypo>
                </SubscribeButton>
              )}
            </AddCartGrid>
          )}
        </Grid>
      </ActionsContainer>

      {isSmall && (
        <PromoDetailsPopper
          countdown={countdown}
          savings={Math.round(product?.discountedPrice) || savings}
          savingsPercent={savingsPercent || product?.discount}
        />
      )}
    </CardPaper>
  );
};

ProductCard.propTypes = {
  product: PropTypes.instanceOf(Object),
  openAlert: PropTypes.func.isRequired,
  cart: PropTypes.bool,
  similarAction: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
  refetchCart: PropTypes.func,
  active: PropTypes.string
};

ProductCard.defaultProps = {
  product: {},
  cart: false,
  refetchCart: () => {},
  active: ''
};

export default ProductCard;
