import React, {
  useState, useLayoutEffect, useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  InputAdornment, Grid, Hidden, useMediaQuery, Card
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  useNavigate, useParams, useLocation
} from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import NewOrder from './newOrder';
import OrderFilter from './filters/orderFilter';
import MainContent from '../../customComponents/mainContent';
import CategoriesContainer from './categories/categoriesContainer';
import SearchIcon from '../../../assets/images/marketOrder/searchIcon.png';
import {
  OrderGridContainer, OrderSubHeader, CategoriesBox, SearchContainer, BoxGrid, MobileSearchText,
  MenuButton, ClearIcon, CategoriesTitle, SearchImage, SearchTextField, SearchButton, MobileSearchButton,
  LeftDrawer, ProductsBox, SearchBox, ContentBox, ProductSide, FilterSide, Header, CheckboxImage, BannerContainer, ImageLink,
} from './marketOrderContainer.styles';
import { useStateValue } from '../../../providers/stateProvider';
import AffiliateActionTypes from '../../../providers/reducers/affiliate/affiliateTypes';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { MARKETPLACE_CATEGORIES } from '../../../queries/categories';
import CheckboxIcon from '../../../assets/images/supplier/Checkbox-selected.png';
import { Filter } from '../../../assets/svgs';
import { BANNERS_PAGE_QUERY } from '../../../queries/banners';
import TabsButtons from './tabsButtons/tabsButtons';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';
import ProductSearchPopper from './productSearchPopper';
import { NEW_ORDER_PRODUCTS_SEARCH_QUERY, CLEARANCE_SEARCH_ORDER_PRODUCT } from '../../../queries/products';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const MarketOrderContainer = ({
  location
}) => {
  const priceRange = [
    {
      priceMin: '0.00',
      priceMax: '5000.00',
      displayPrice: '0 - 5,000'
    },
    {
      priceMin: '5000.00',
      priceMax: '10000.00',
      displayPrice: '5,000 - 10,000 and above'
    }
  ];

  const { pathname } = location;
  const isSmall = useMediaQuery('(max-width: 991px)');
  const {
    category, categoryId, productId, topSellingProducts,
  } = useParams() || '';
  const navigate = useNavigate();
  const urlLocation = useLocation();
  const initialState = {
    searchText: '',
    searchValue: '',
    filteredSupplier: new Map(),
    suppliers: '',
    drawer: false,
    priceMin: false,
    priceMax: false,
    availabilityStatus: '',
    manufacturer: []
  };

  const [state, setState] = useState(initialState);
  const [reloadfilter, setReloadfilter] = useState(false);
  const [active, setActive] = useState('all');
  const [filterType, setFilterType] = useState('');
  const [filters, setFilters] = useSearchParamsState('filters', '');
  const vals = filters.split(',');
  const [brandSearch, setBrandSearch] = useSearchParamsState('manuf', '');
  const [subCatUrl, setSubCatUrl] = useSearchParamsState('subCat', '');
  const [searchFieldEl, setSearchFieldEl] = useState(null);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [savedEl, setSavedEl] = useState(null);
  const textFieldRef = useRef(null);
  const searchContainerRef = useRef(null);

  const {
    drawer, searchValue: stateSearchValue, priceMin, priceMax, manufacturer, availabilityStatus
  } = state;

  useEffect(() => {
    setState((s) => ({
      ...s, searchValue: vals[0] || '', priceMin: vals[1] || null, priceMax: vals[2] || null, availabilityStatus: vals[3] || '', topSellingProducts: vals[4] || '',
      manufacturer: brandSearch ? JSON.parse(brandSearch) : [], therapeuticTypeIds: subCatUrl || []
    }));

    if (textFieldRef.current && document.activeElement === textFieldRef.current && searchFieldValue === '' && vals[0].length) {
      const values = `${''},${priceMin},${priceMax},${availabilityStatus}`;
      setFilters(values);
      setSearchedProducts([]);
      setSearchFieldEl(null);
      setSavedEl(null);
    }

    if (vals[0].length && searchFieldValue.length < 1 && document.activeElement !== textFieldRef.current) {
      setSearchFieldValue(vals[0]);
    }
  }, [filters, vals, brandSearch, subCatUrl, searchFieldValue]);

  const {
    loading, data: categoriesList
  } = useQuery(MARKETPLACE_CATEGORIES, {
    fetchPolicy: 'cache-first',
    onCompleted: () => {
      setReloadfilter(true);
    },
  });

  const {
    data: bannerData
  } = useQuery(BANNERS_PAGE_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const variables = {
    search: searchFieldValue,
    topSelling: (active === 'topSellingProducts') && true,
    ...(active === 'clearanceSale' && { suggestive: true }),
    pageCount: 10,
    pageNumber: 1
  };

  const { loading: searchLoading, data: searchData } = useQuery(NEW_ORDER_PRODUCTS_SEARCH_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables,
    skip: !searchFieldValue || topSellingProducts === 'Clearance Sale'
  });

  const { loading: searchClearanceLoading, data: searchClearanceData } = useQuery(CLEARANCE_SEARCH_ORDER_PRODUCT, {
    fetchPolicy: 'cache-and-network',
    variables,
    skip: topSellingProducts !== 'Clearance Sale'
  });

  useEffect(() => {
    if (searchData && searchData.newOrderPageProducts) {
      setSearchFieldEl(savedEl);
      setSearchedProducts(searchData?.newOrderPageProducts);
    }
  }, [searchData]);

  useEffect(() => {
    if (searchClearanceData && searchClearanceData.clearanceOrderPageProducts) {
      setSearchFieldEl(savedEl);
      setSearchedProducts(searchClearanceData?.clearanceOrderPageProducts);
    }
  }, [searchClearanceData]);

  const handlePopperClose = () => {
    setSearchFieldEl(null);
    setSavedEl(null);
  };

  const {
    bannerPages = [],
  } = bannerData || {};

  const homepageBanner = bannerPages?.filter((item) => item.page === 'Homepage (Hero Section)')?.flatMap((item) => item.meta.map((metaItem) => JSON.parse(metaItem)));
  const categoryBanners = bannerPages?.filter((item) => item.page === 'CATEGORIES')?.flatMap((item) => item.meta.map((metaItem) => JSON.parse(metaItem)));
  const middleBanner = bannerPages?.filter((item) => item.page === 'Homepage (Middle Section)')?.flatMap((item) => item.meta.map((metaItem) => JSON.parse(metaItem)));
  const newHomePage = homepageBanner.filter((item) => item.name !== 'othersImage1' && item.name !== 'othersImage2');
  const otherBanners = homepageBanner.filter((item) => item.name === 'othersImage1' || item.name === 'othersImage2');
  const categories = categoriesList?.categories || [];

  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    if (topSellingProducts) {
      if (topSellingProducts === 'Top Selling Products') {
        setActive('topSellingProducts');
      } else if (topSellingProducts === 'Clearance Sale') {
        setActive('clearanceSale');
      }
    }
  }, [topSellingProducts]);

  useLayoutEffect(() => {
    dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        addAffiliateDialogOpen: true
      }
    });
    dispatch({
      type: CartActionTypes.UPDATE_IS_MANUAL,
      payload: pathname?.startsWith('/manual-order')
    });
  }, [searchFieldValue]);

  const handleSearch = (event, text) => {
    setSearchFieldValue(text);
    setSavedEl(event.target);
    setSearchFieldEl(event.target);
    if (text.length < 1) {
      setSearchedProducts([]);
      setSearchFieldEl(null);
      setSavedEl(null);
    }
  };

  const handleProdSelection = (name) => {
    const values = `${name},${priceMin},${priceMax},${availabilityStatus}`;
    setFilters(values);
    setSearchFieldValue(name);
    setSearchedProducts([]);
    handlePopperClose();
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      const values = `${searchFieldValue},${priceMin},${priceMax},${availabilityStatus}`;
      setFilters(values);
      handlePopperClose();
    }
  };

  const handlePriceFilter = (price) => {
    if (priceMin === price.priceMin && priceMax === price.priceMax) {
      const values = `${stateSearchValue},${''},${''},${availabilityStatus}`;
      setFilters(values);
      setState({ ...state, priceMin: null, priceMax: null });
      return;
    }
    const values = `${stateSearchValue},${price.priceMin},${price.priceMax},${availabilityStatus}`;
    setFilters(values);
    setState({ ...state, priceMin: price.priceMin, priceMax: price.priceMax });
  };

  const handleBrandFilter = (brand) => {
    if (manufacturer?.includes(brand)) {
      const brandFilter = manufacturer.filter((item) => item !== brand);
      setBrandSearch(JSON.stringify(brandFilter));
      return setState({ ...state, manufacturer: JSON.stringify(brandFilter) });
    }
    setBrandSearch(JSON.stringify([...manufacturer, Number(brand)]));
    return setState({ ...state, manufacturer: [...manufacturer, Number(brand)] });
  };

  const handleProductsAvailability = (variant) => {
    const values = `${stateSearchValue},${priceMin},${priceMax},${availabilityStatus === variant ? '' : variant}`;
    setFilters(values);
    setState({ ...state, availabilityStatus: availabilityStatus === variant ? '' : variant });
  };

  const clearFilter = async () => {
    try {
      await setBrandSearch(JSON.stringify([]));
      await setSubCatUrl([]);
      await setFilters('');
    } catch (error) {
      console.error('Error', error);
    }
  };

  useEffect(() => {
    const clearAndNavigate = async () => {
      try {
        await clearFilter();
        navigate(urlLocation.pathname);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (filterType === 'clearFilters') {
      clearAndNavigate();
    }
  }, [filterType, urlLocation.pathname]);

  const handleClearFilter = async (type) => {
    switch (type) {
      case 'manufacturer':
        setBrandSearch(JSON.stringify([]));
        return setState({ ...state, manufacturer: [] });
      case 'therapeuticType':
        setSubCatUrl(JSON.stringify([]));
        return setState({ ...state, therapeuticTypeIds: [] });
      case 'clearFilters':
        setState(initialState);
        return setFilterType(type);
      default:
        dispatch({ type: 'hideNavbar', payload: false });
        return setState(initialState);
    }
  };

  const toggleDrawer = (close) => {
    setState((_state) => ({ ..._state, drawer: !_state.drawer }));
    if (close) return dispatch({ type: 'hideNavbar', payload: false });
    return dispatch({ type: 'hideNavbar', payload: true });
  };

  const handleClearSearch = () => {
    const values = `${''},${priceMin},${priceMax},${availabilityStatus}`;
    setFilters(values);
    setSearchFieldValue('');
  };

  const handleSuppliers = (id) => {
    setState({ ...state, suppliers: id });
  };

  const handleMobileSearch = () => {
    const values = `${searchFieldValue},${priceMin},${priceMax},${availabilityStatus}`;
    setFilters(values);
    handlePopperClose();
  };

  const renderSearchField = (
    placeholder, searchValue, handleChange, ClearSearch, handleKeyPress, small = false, joyClass = 'joy-search-product'
  ) => (
    <SearchContainer className={joyClass} ref={searchContainerRef}>
      <SearchTextField
        variant="outlined"
        inputRef={textFieldRef}
        label=""
        autoFocus={placeholder === 'Search products item'}
        value={searchValue}
        onChange={(e) => handleChange(e, e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder={placeholder}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: '25px' }}>
              <SearchButton
                aria-label={placeholder}
                edge="start"
                disabled={!searchValue}
                small={small}
                onClick={ClearSearch}
              >
                {searchValue ? (
                  <ClearIcon small={small} />
                ) : (
                  <SearchImage alt="search icon" src={SearchIcon} small={small} />
                )}
              </SearchButton>
            </InputAdornment>
          ),
          endAdornment: isSmall ? (
            <InputAdornment position="end" style={{ marginRight: '25px' }}>
              <MobileSearchButton onClick={handleMobileSearch}>
                <MobileSearchText>
                  Search
                </MobileSearchText>
              </MobileSearchButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </SearchContainer>
  );

  const [subcategories, setSubcategories] = useState([]);
  const [isSelected, setIsSelected] = useState('All Categories');
  const [displaySubCategory, setDisplaySubCategory] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleSelectCategory = (id, name) => {
    setIsSelected(name || 'All Categories');
    setSelectedId({ id, name });
    const subData = categories?.find((data) => data.id === id);
    if (subData && subData.subTherapeuticType.length) {
      const ids = subData.subTherapeuticType.map((type) => type.id);
      setState({ ...state, therapeuticTypeIds: ids });
      setDisplaySubCategory(true);
      return setSubcategories(subData.subTherapeuticType);
    }
    handleClearFilter('therapeuticTypeIds');
    setDisplaySubCategory(false);
    return setSubcategories([]);
  };

  const handleSubProducts = (id) => {
    const { therapeuticTypeIds } = state;
    if (therapeuticTypeIds === id) {
      const { id: checkedId, name } = selectedId;
      setSubCatUrl([]);
      handleSelectCategory(checkedId, name);
      return;
    }
    setSubCatUrl(id);
    setState({ ...state, therapeuticTypeIds: id });
  };

  useEffect(() => {
    handleSelectCategory(categoryId, category);
  }, [categoryId, category, reloadfilter]);

  const renderCategories = () => {
    if (!loading) {
      return (
        <CategoriesContainer
          handleSelect={handleSelectCategory}
          isSelected={isSelected}
        />
      );
    }
    return null;
  };
  const selectedCategoryImage = categoryBanners?.find((item) => item.category_id === selectedId?.id);

  const tabs = [
    {
      label: 'All Categories', value: 'all'
    },
    {
      label: 'Top selling', value: 'topSellingProducts'
    },
    {
      label: 'Clearance Sale', value: 'clearanceSale'
    }
  ];

  const handleTabChange = (val) => {
    setActive(val);
    setSearchFieldValue('');
    if (val === 'topSellingProducts') {
      navigate('/new-order/All Categories/0/0/Top Selling Products');
    } else if (val === 'clearanceSale') {
      navigate('/new-order/All Categories/0/0/Clearance Sale');
    } else {
      navigate('/new-order');
    }
  };

  const handleBannerClick = (imageUrl) => imageUrl && window.open(imageUrl);

  return (
    <MainContent>
      <CategoriesBox data-testid="authOrdersPage">
        {renderCategories()}
      </CategoriesBox>
      <BannerContainer container xs={12} spacing={2}>
        {isSelected === 'All Categories' ? (
          <>
            {newHomePage?.length ? (
              <Grid item xs={(isSmall) ? 12 : 9} style={{ maxHeight: '350px' }}>
                <Swiper
                  centeredSlides
                  autoplay={{
                    delay: 4500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  {newHomePage?.map((item) => (
                    <SwiperSlide key={item} onClick={() => handleBannerClick(item.url)}>
                      <Card style={{ height: '335px', display: 'flex', boxShadow: 'none' }}>
                        <img
                          src={item.banner}
                          alt="Paella dish"
                          style={{
                            objectFit: 'fill', width: '100%', height: '100%', cursor: 'pointer'
                          }}
                          loading="lazy"
                        />
                      </Card>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>

            ) : ''}
            <Hidden mdDown>
              {otherBanners?.length ? (
                <Grid item xs={3} container spacing={2}>
                  <Grid item xs={12} style={{ height: '175px' }}>
                    <Card style={{ height: '100%', display: 'flex', boxShadow: 'none' }} onClick={() => handleBannerClick(otherBanners[0]?.url)}>
                      <img
                        src={otherBanners[0]?.banner}
                        alt="Paella dish"
                        style={{
                          objectFit: 'fill', width: '100%', height: '100%', cursor: 'pointer'
                        }}
                        loading="lazy"
                      />
                    </Card>
                  </Grid>
                  <Grid style={{ height: '175px' }} item xs={12}>
                    <Card style={{ height: '100%', display: 'flex', boxShadow: 'none' }} onClick={() => handleBannerClick(otherBanners[1]?.url)}>
                      <img
                        src={otherBanners[1]?.banner}
                        alt="Paella dish"
                        style={{
                          objectFit: 'fill', width: '100%', height: '100%', cursor: 'pointer'
                        }}
                        loading="lazy"
                      />
                    </Card>
                  </Grid>
                </Grid>
              ) : ''}
            </Hidden>
          </>
        ) : ''}
        {isSelected !== 'All Categories' && selectedCategoryImage ? (
          <Grid item xs={12} style={{ height: '256px' }}>
            <Card style={{ height: '100%', display: 'flex', boxShadow: 'none' }}>
              <ImageLink href={selectedCategoryImage?.carousel_url}>
                <img
                  src={selectedCategoryImage?.carousel_image}
                  alt="Paella dish"
                  style={{ objectFit: 'fill', width: '100%', height: '100%' }}
                  loading="lazy"
                />
              </ImageLink>
            </Card>
          </Grid>
        ) : ''}
      </BannerContainer>

      <ContentBox>
        <OrderGridContainer container>
          <LeftDrawer anchor="left" open={drawer} onClose={() => toggleDrawer()}>
            <OrderFilter
              className="order-area"
              checkedIcon={checkedIcon}
              icon={icon}
              subcategories={subcategories}
              priceRange={priceRange}
              containerState={state}
              handlePriceFilter={handlePriceFilter}
              handleSubProducts={handleSubProducts}
              handleSuppliers={handleSuppliers}
              handleClearFilter={handleClearFilter}
              handleBrandFilter={handleBrandFilter}
              displaySubCategory={displaySubCategory}
              handleProductsAvailability={handleProductsAvailability}
              open={drawer}
              handleClose={toggleDrawer}
            />
          </LeftDrawer>
          <BoxGrid container>
            <FilterSide>
              <Hidden smDown>
                <OrderFilter
                  checkedIcon={checkedIcon}
                  icon={icon}
                  subcategories={subcategories}
                  priceRange={priceRange}
                  containerState={state}
                  handlePriceFilter={handlePriceFilter}
                  handleSubProducts={handleSubProducts}
                  handleSuppliers={handleSuppliers}
                  handleClearFilter={handleClearFilter}
                  handleBrandFilter={handleBrandFilter}
                  displaySubCategory={displaySubCategory}
                  handleProductsAvailability={handleProductsAvailability}
                  open={drawer}
                  handleClose={toggleDrawer}
                />
              </Hidden>
            </FilterSide>

            <ProductSide>
              <ProductsBox>
                <Header container xs={12}>
                  <Grid item xs={12} md={7}>
                    {isSelected === 'All Categories' ? (
                      <TabsButtons
                        tabVal={active}
                        handleTabChange={handleTabChange}
                        tab={tabs}
                      />
                    ) : (
                      <CategoriesTitle>
                        {isSelected}
                      </CategoriesTitle>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <OrderSubHeader container spacing={isSmall ? 3 : ''}>
                      <SearchBox item lg={12} md={10} xs={10}>
                        <Hidden mdUp>
                          {renderSearchField('Search for Products', searchFieldValue, handleSearch, handleClearSearch, handleEnterPress, false, 'joy-search-product')}
                        </Hidden>
                        <Hidden mdDown>
                          {renderSearchField('Search for Products', searchFieldValue, handleSearch, handleClearSearch, handleEnterPress)}
                        </Hidden>
                      </SearchBox>
                      <SearchBox item xs={2} md={2}>
                        <Hidden mdUp>
                          <MenuButton onClick={() => toggleDrawer()}>
                            <Filter />
                          </MenuButton>
                        </Hidden>
                      </SearchBox>
                    </OrderSubHeader>
                  </Grid>
                </Header>

                <NewOrder
                  state={state}
                  productId={productId}
                  active={active}
                  banner={middleBanner}
                />

                <ProductSearchPopper
                  searchFieldEl={searchFieldEl}
                  handleClose={handlePopperClose}
                  searchedProducts={searchedProducts}
                  handleProdSelection={handleProdSelection}
                  loading={searchLoading || searchClearanceLoading}
                  width={searchContainerRef.current ? searchContainerRef.current.offsetWidth : null}
                />
              </ProductsBox>
            </ProductSide>
          </BoxGrid>
        </OrderGridContainer>
      </ContentBox>
    </MainContent>
  );
};

MarketOrderContainer.propTypes = {
  location: PropTypes.instanceOf(Object),
};

MarketOrderContainer.defaultProps = {
  location: {},
};

export default MarketOrderContainer;
