import {
  Grid, Typography, IconButton, CircularProgress,
  TextField, Divider, Paper, DialogTitle,
  Dialog, Box, Button,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Close, RemoveCircleOutline } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
  }
`;

export const GridWrapper = styled(Grid)`
justify-content: flex-end;
  width: 100%;
  & > * {
    padding: .5rem 3rem;
  }
`;
export const ContainerWrapper = styled(Grid)`
  background-color: #fff;
  padding: 2rem 9rem;
  min-height: 100vh;
  @media(max-width: 991px) {
    padding: 2rem 3rem;
  }
`;

export const HeaderWrapper = styled(Grid)`
  padding: 2rem 0rem;
  justify-content: space-between;

`;

export const Title = styled(Typography)`
  font-weight: 700;
  color: #000000;
  font-size: 24px;
  text-align: left;

  @media(max-width: 991px) {
    font-size: 2.5rem;
  }

`;
export const TitleWrapper = styled(Grid)`
padding: 10px 0px 20px 0px;

`;

export const TitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 22px;
  text-align: left;

  @media(max-width: 991px) {
    font-size: 2.8rem;
  }
`;

export const DialogContentWrapper = styled(Grid)`
`;

export const DividerLine = styled('hr')`
  border-right: 1px solid #CCCCCC;
  height: 80%;
`;

export const SampleCsvWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  justify-content: flex-start;
  text-align: left;
`;

export const SampleCsvDownload = styled(Grid)`
  width: 100%;
  background: #E0E0E0;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
`;

export const CsvFileImage = styled('img')`
  height: 30px;
  margin-right: 25px;
`;
export const CsvDescription = styled(Grid)`

`;
export const CsvTitle = styled(Typography)`
  font-size: 16px;
`;
export const CsvSubtitle = styled(Typography)`
  font-size: 12px;
`;
export const ButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;

  @media(max-width: 991px) {
    font-size: 30px;
  }
`;
export const DownloadIconWrapper = styled(IconButton)`
`;
export const DownloadIconImage = styled('img')`
  height: 30px;
`;

export const EditButton = styled(IconButton)`
  height: 55px;
  padding: 0px 30px;
  color: #FFFFFF;
  background-color: #235A91;
  border-radius: 5px;
  margin-right: 15px;
  &:hover {
    background-color: #235A91;
  }

  @media(max-width: 991px) {
    height: 7rem;
    width: 100%;
    margin-top:30px;
  }
`;

export const CancelButton = styled(IconButton)`
  height: 50px;
  width: 80px;
  padding: 0px 25px;
  color: #000000;
  border: 1px solid #000;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 40px;
  &:hover {
    background-color: #000000;
    color: #FFF;
  }

  @media(max-width: 991px) {
    height: 5rem;
    width: 100%;
  }
  `;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #FFFFFF;
  margin-top: 4px;
  & .MuiCircularProgress-svg {
    width: 1.6rem;
  }
`;

export const UploadGrid = styled(Grid)`
  padding: 0 10px;
  height: 10rem;

  @media(max-width: 991px) {
    height: 50rem;
    margin: 3rem 0;
  }
`;

export const ProfileGrid = styled(Paper)`
  padding: 3rem 30px;
  box-shadow: 0px 2.9600000381469727px 66.63999938964844px 0px #A3ABB91F;
  border-radius: 12px;
  width: 100%;
  @media(max-width: 991px) {
  padding: 3rem 60px;
  }
`;

export const Image = styled('img')`
  max-width: 100%;
  border-radius: 50%;
`;

export const SupplierTextField = styled(TextField)`
  width: 50%;
  .MuiFilledInput-root {
    background-color:  #F5F5F7;
    border-radius: 15px;
  }
  & .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  & .MuiInputBase-input.Mui-disabled {
    color: #000000;
  }
  & .MuiFormLabel-root.Mui-disabled {
    color: #000000;
  }
  @media(max-width: 991px) {
    .MuiFilledInput-root {
      font-size: 2.4rem;
      border-radius: 20px;
      padding: 1rem;
  }
  & .MuiInputLabel-filled {
      font-size: 1.8rem;
    }
  }
`;

export const FieldGrid = styled(Grid)`
    & > * {
      width: 80%;
      margin: 0 0 1.5rem;
    }
    @media(max-width: 991px) {
      & > * {
        width: 100%;
      }
    }
`;

export const MiddleGrid = styled(Grid)`
    justify-content: space-between;
`;

export const ButtonGrid = styled(Grid)`
    display: flex;
    justify-content: right;
    margin-right: 3.5rem;

    @media(max-width: 991px) {
      justify-content: center;
      margin-right: 0rem;
      margin-left: 1rem;
    }
`;

export const ReasonGrid = styled(Grid)`
  justify-content: center;

    & > * {
      width: 80%;
      margin: 0 0 1.5rem;
    }
    @media(max-width: 991px) {
      & > * {
        width: 100%;
      }
    }
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const FormLabel = styled(Typography)`
  color: #A3A3A3;
  font-size: 17px;

  @media(max-width: 991px) {
    font-size: 27px;
  }
`;

export const MessageBoxContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
`;

export const MessageBoxTextField = styled(TextField)`
  background-color: #F0F0F0;
  height: 55px;

  label + .MuiInput-formControl {
    margin-top: 21px;
  }

  label {
    left: 20px;
    top: 5px;
    color: #A3A3A3;
  }

  input, textarea {
    padding-left: 20px;
    background-color: #F0F0F0;
    color: #424242;
    font-size: 16px;
    margin-top: 5px;
  }

  @media (max-width: 991px) {
    height: 80px;

    label + .MuiInput-formControl {
      margin-top: 23px;
    }

    label {
      font-size: 1.6rem;
    }

    input, textarea {
      font-size: 2rem;
    }
  }
`;

export const LightDivider = styled(Divider)`
  background-color: #D6D6D6;

  @media(min-width: 992px) {
    background-color: #F0F0F0;
  }
`;

export const TextWrapper = styled(Grid)`
  padding: 15px 0px;

  @media(max-width: 991px) {
    padding: 25px 0px;
  }

`;

export const TextTitle = styled(Typography)`
  font-weight: 400;
  color: #606060;
  font-size: 15px;
  text-align: left;
  padding-bottom: 7px;

  @media(max-width: 991px) {
    font-size: 2.3rem;
    padding-bottom: 15px;
  }
`;

export const Text = styled(Typography)`
  font-weight: 500;
  color: #303030;
  font-size: 18px;
  text-align: left;

  @media(max-width: 991px) {
    font-size: 2.3rem;
  }
`;

export const DialogTitleContainer = styled(DialogTitle)`
  width: 100%;
  margin-top: 15px;
`;

export const DialogTitleWrapper = styled(Grid)`
  display: flex;
  margin-top: 14px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 600;
  color: #303030;
  font-size: 27px;
  margin-bottom: 10px;

  @media(max-width: 991px) {
    font-size: 2.3rem;
  }
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 16px;
  color: #424242;
  margin-bottom: 15px;
`;
export const DialogOkButton = styled(IconButton)`
  height: 45px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  background: #235A91;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff;
  margin-bottom: 40px;
  margin-top: 40px;

  &:hover {
    background-color: #235A91;
  }

  @media(max-width: 991px) {
    height: 90px;
    width: 300px;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 45px;
  width: 170px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  color: #235A91;
  border-radius: 5px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  margin-right: 15px;
  margin-bottom: 40px;
  margin-top: 40px;
  border: 1px solid #235A91;

  @media(max-width: 991px) {
    height: 90px;
    width: 300px;
  }
  `;

export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;

  @media(max-width: 991px) {
    font-size: 30px;
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;

  @media(max-width: 991px) {
    width: 50px;
    height: 60px;
  }
`;

export const ItemWrapper = styled(Grid)`
  display: grid;
  grid-template-columns: auto auto;
  gap: 15px 30px;

  @media(max-width: 991px) {
    grid-template-columns: auto;
    gap: 30px;
  }
`;

export const InputItemWrapper = styled(Grid)`
  display: grid;
  grid-template-columns: auto auto;
  gap: 15px 30px;

  @media(max-width: 991px) {
    grid-template-columns: auto;
    gap: 30px;
    overflow: scroll
  }
`;

export const LinkPosPaper = styled(Paper)`
  padding: 2rem 30px;
  box-shadow: 0px 2.9600000381469727px 66.63999938964844px 0px #A3ABB91F;
  border-radius: 12px;
  width: 100%;
  margin-top: 2rem;

  @media(max-width: 991px) {
    padding: 3rem 60px;
  }
`;

export const LinkText = styled(Typography)`
  font-size: 1.375rem;
  font-weight: 700;
  color: #303030;
`;

export const LinkSubText = styled(Typography)`
  font-size: .875rem;
  font-weight: 400;
  color: #606060;
  margin-top: .3rem;
`;

export const LinkBox = styled(Box)`
  height: 7.25rem;
  width: 100%;
  margin-top: 1.5rem;
  background-color: #f5faff;
  border-radius: 8px;
  padding: 2rem;
`;

export const NoLinkText = styled(Typography)`
  color: #000000;
  font-size: 1rem;
  font-weight: 700;
`;

export const NolinkSubText = styled(Typography)`
  color: #606060;
  font-weight: 500;
  font-size: .875rem;
  margin-top: 5px;
`;

export const LinkButton = styled(Button)`
  border-radius: 6px;
  background-color: #235a91;
  width: 8.813rem;
  height: 3rem;
  text-align: center;
  font-size: .875rem;
  font-weight: 600;
  color: #ffffff;

  &:hover {
    background-color: #235a91;
    font-size: .875rem;
    font-weight: 600;
    color: #ffffff;
  }
`;

export const LinkedName = styled(Typography)`
  font-weight: 700;
  font-size: 1rem;
  color: #000000;
`;

export const LinkedType = styled(Typography)`
  font-weight: 500;
  font-size: .875rem;
  color: #606060;
`;

export const RemoveIcon = styled(RemoveCircleOutline)`
  fill: #e74c3c;
  margin-top: 5px;
  font-size: 1.5rem;
`;

export const RemoveText = styled(Typography)`
  font-weight: 600;
  font-size: .875rem;
  color: #235a91;
  margin-top: 5px;
  margin-left: 8px;
`;

export const RemoveButton = styled(Button)`
`;

export const LinkDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
    padding-bottom: 2.5rem;
    max-width: 36.188rem;
  }
`;

export const EclipseDesign = styled(Box)`
  border-radius: 50%;
  height: 5.063rem;
  width: 5.063rem;
  background-color: #f1f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OgaLinkTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;
  color: #303030;
  text-align: center;
`;

export const OgaLinkSubTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  color: #606060;
  margin-top: .5rem;
  line-height: 26.27px;
`;

export const TextBox = styled(Typography)`
  text-align: center;
  margin-top: .5rem;
`;

export const LinkCodeText = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #235a91;
  margin-top: 1rem;
`;

export const CopyButton = styled(Button)`
  border: 1px solid rgba(35, 90, 145, 1);
  border-radius: 5px;
  width: 13.438rem;
  height: 3rem;
  color: #235a91;
  font-size: .875rem;
  font-weight: 700;
  text-align: center;
`;

export const RedirectButton = styled(Button)`
  border-radius: 5px;
  width: 13.375rem;
  height: 3rem;
  color: #ffffff;
  font-size: .875rem;
  font-weight: 700;
  box-shadow: 0px 4px 14px 0px;
  background-color: #235a91;
  text-align: center;

  &:hover {
    background-color: #235a91;
    color: #ffffff;
  }
`;

export const LinkDialogActions = styled(DialogActions)`
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;
