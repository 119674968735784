import { useMutation, useQuery } from '@apollo/client';
import { Grid, Hidden, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AffiliatesImage from '../../assets/images/navigation/Affiliate_Placeholder.png';
import {
  BackOrderIcon, BatchDetails, BellIco, BoxStore, CartIco, Categories, CloseIconBlue, Dashboard,
  Dollar, Inventory, MangeOrderIcon, MenuToggle, MgAffiliate, Orders, OrdersTruck, ProductType,
  PromoIcon, Returns, Sales, ScReport, Setting, StTransfer, Timer, Toggle, TrackOrderIcon, Users, UploadIcon
} from '../../assets/svgs';
import { CREATE_ORDER_MUTATION } from '../../mutations/orders';
import AffiliateActionTypes from '../../providers/reducers/affiliate/affiliateTypes';
import CartActionTypes from '../../providers/reducers/cart/cartTypes';
import NotificationsActionTypes from '../../providers/reducers/notifications/notificationsTypes';
import { useStateValue } from '../../providers/stateProvider';
import { ERP_NOTIFICATIONS } from '../../queries/notifications';
import { Subscriber } from '../../utils/subscriber';
import { getUser } from '../../utils/utils';
import InfoTillDialogue from '../categories/infoTillDialog';
import SimpleBadge from '../shared/simpleBadge';
import AffiliatesPopper from './affiliatesPopper';
import {
  AffiliateImage, AffiliatesLogo, BellButton, CloseMenuButton, DateTimeTypo, DrawerContainer,
  DropDownArrow, HeaderIcon, HeaderMenuButton, LogoIcon, MpLogo, NavItem, NavLeftMenu,
  NavRightMenu, NavbarContainer, OgaAdminLogo, ProfileTypo, SideAffiliateImage, SideLogoIcon,
  SideNavHeader, SideNavItems, SideProfileTypo, ViewCartTypo, WHLogo, WarehouseLg
} from './navigationContainer.styles';
import NotificationsPopper from './notificationsPopper';
import SideNavItem from './sideNavItem';

export const NavigationContainer = () => {
  const [dateState, setDateState] = useState(new Date());
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [manualOrder, setManualOrder] = useState(false);
  const [affiliatesLogoEl, setAffiliatesLogoEl] = useState(null);
  const [notificationsEl, setNotificationsEl] = useState(null);
  const [openTillDialogue, setOpenTillDialogue] = useState(false);
  const raspEnv = process.env.RASPBERRY_ENV;
  const navigate = useNavigate();

  const [
    {
      user,
      cart: { cartCounter },
      affiliate: { isBusinessVirtualStore },
      notifications: { count }
    },
    dispatch
  ] = Object.values(useStateValue());

  const {
    session = {}, promos = {}, priceFactor, businessName, businessUserId, allowedPermissionsMap: { userPermissions },
    platform
  } = user;

  const loggedInUser = getUser(session);
  const CloseShift = 'Close Shift';
  const isSmallScreen = useMediaQuery('(max-width: 991px)');

  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  const updateCartTotal = (currentOrder) => {
    const { totalProductCount } = currentOrder || {};

    dispatch({
      type: CartActionTypes.CART_COUNT,
      payload: totalProductCount
    });
  };

  const [createOrder] = useMutation(CREATE_ORDER_MUTATION, {
    onCompleted(data) {
      updateCartTotal(data?.createOrder);
    }
  });

  const location = useLocation();
  useEffect(() => {
    const usablePromos = [];
    const { availablePromos, affiliatePromos } = promos;

    const searchableAvailablePromos = `**${availablePromos?.join('**')}**`;
    affiliatePromos?.forEach((eachPromo) => {
      if (searchableAvailablePromos.indexOf(`**${eachPromo}**`) > -1) {
        usablePromos.push(eachPromo);
      }
    });

    dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        affiliatePromos,
        availablePromos,
        usablePromos,
        pricePolicyFactor: priceFactor
      }
    });
  }, []);

  useEffect(() => {
    const { pathname: pname } = location;
    const role = localStorage.getItem('oga_user_role');
    if (role === 'store-manager' || role === 'chain-manager') {
      setManualOrder(true);
      createOrder({
        variables: {
          isManualOrder: true
        }
      });
    } else if (pname !== '/') {
      setManualOrder(false);
      createOrder();
    }
  }, [location, createOrder]);

  const { businessuserSet = [] } = session;
  const { logo } = businessuserSet.length && businessuserSet[0].business;
  const affiliateLogo = logo || AffiliatesImage;

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
    dispatch({ type: 'openSideDrawer' });
  };

  const role = localStorage.getItem('oga_user_role');

  const returnRoute = (name) => {
    switch (name) {
      case 'oga-pharmacy-admin':
        return '/orders-admin';
      case 'affiliate-admin':
        return '/orders';
      case 'store-manager':
        return '/sales';
      case 'supplier-admin':
        return '/orders-supplier';
      default:
        return null;
    }
  };

  const handleSideClick = (route) => {
    toggleSideNav();
    setTimeout(() => {
      if (route === 'orders' && !isBusinessVirtualStore) {
        navigate(returnRoute(role));
        return;
      }
      navigate(`/${route}`);
    }, 500);
  };

  const handleAffiliateLogoClick = (event) => {
    setAffiliatesLogoEl(affiliatesLogoEl ? null : event?.currentTarget);
  };
  const handleBellClick = (event) => {
    setNotificationsEl(notificationsEl ? null : event.currentTarget);
  };

  const OGA_PHARMACY_ADMIN_SIDE_ITEMS = [
    ['dashboard', Dashboard, 'dashboard'],
    ['orders-admin', Users, 'Manage Orders', 'manage_orders_view_manage_orders'],
    ['manage-affiliates', MgAffiliate, 'Manage Affiliates', 'manage_affiliates_view_manage_affiliates'],
    ['customer-categories', Categories, 'Customer Categories'],
    ['banners', BatchDetails, 'Manage Banners'],
    ['payments', Dollar, 'Payments', 'payment_view_payment'],
    ['downloads', ScReport, 'Downloads'],
    ['roles', Setting, 'Roles', 'role_permissions_view_role_permissions'],
    ['uploads', UploadIcon, 'Uploads'],
  ];
  const STORE_MANAGER_SIDE_ITEMS = [
    ['sc-dashboard', Dashboard, 'SC dashboard', 'sc_dashboard_view_sc_dashboard'],
    ['categories', Categories, 'Pricing categories', 'pricing_categories_view_pricing_categories'],
    ['suppliers', OrdersTruck, 'suppliers/manufact..', 'suppliers_view_suppliers'],
    ['inventory', Inventory, 'inventory', 'inventory_view_inventory'],
    ['back-order', BackOrderIcon, 'back order'],
    ['stock-transfer', StTransfer, 'stock transfer', 'stock_transfer_view_stock_transfer'],
    ['stock-count-report', ScReport, 'stock count report', 'stock_count_report_view_stock_count_report'],
    ['roles', Setting, 'Roles', 'role_permissions_view_role_permissions'],
    ['downloads', ScReport, 'Downloads'],
    ['promotions', PromoIcon, 'Promotions', 'promotions_view_promotions'],
    ['uploads', UploadIcon, 'Uploads'],
  ];
  const CHAIN_MANAGER_SIDE_ITEMS = [
    ['affiliate-dashboard', Dashboard, 'affiliate dashboard', 'affiliate_dashboard_view_affiliate_dashboard'],
    ['return', Returns, 'Returns', 'returns_view_returns'],
    ['sc-dashboard', Dashboard, 'SC dashboard', 'sc_dashboard_view_sc_dashboard'],
    ['customers', Users, 'customers', 'customers_view_customers'],
    ['suppliers', OrdersTruck, 'suppliers/manufact..', 'suppliers_view_suppliers'],
    ['inventory', Inventory, 'inventory', 'inventory_view_inventory'],
    ['back-order', BackOrderIcon, 'back order'],
    ['sales', Sales, 'sales', 'sales_view_sales'],
    ['stock-transfer', StTransfer, 'stock transfer', 'stock_transfer_view_stock_transfer'],
    // ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
    ['stock-count-report', ScReport, 'stock count report', 'stock_count_report_view_stock_count_report'],
    // ['therapeutic-types', ProductType, 'product types', 'product_type_view_product_type'],
    ['roles', Setting, 'Roles', 'role_permissions_view_role_permissions'],
    ['cm-dashboard', Dashboard, 'CM dashboard', 'cm_dashboard_view_cm_dashboard'],
    ['downloads', ScReport, 'Downloads'],
    ['promotions', PromoIcon, 'Promotions', 'promotions_view_promotions'],
    ['uploads', UploadIcon, 'Uploads'],
  ];
  const VIRTUAL_STORE_SIDE_ITEMS = [
    ['orders', Orders, 'orders'],
    ['orders-admin', Orders, 'order processing'],
    ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
  ];
  const SUPPLIER_SIDE_ITEMS = [
    ['products', BatchDetails, 'products'],
    ['orders', Orders, 'orders']
  ];
  const AFFILIATE_SIDE_ITEMS = [
    ['orders', Orders, 'orders'],
    ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
  ];
  const AFFILIATE_ADMIN_SIDE_ITEMS = [
    ['orders', MangeOrderIcon, 'Manage Orders'],
    ['track-order', TrackOrderIcon, 'Track Order'],
    ['uploads', UploadIcon, 'Uploads']
  ];

  const STORE_MANAGER_SIDE_ITEMS_OFFLINE = [
    ['categories', Categories, 'Pricing categories', 'pricing_categories_view_pricing_categories'],
    ['affiliate-dashboard', Dashboard, 'affiliate dashboard', 'affiliate_dashboard_view_affiliate_dashboard'],
    ['return', Returns, 'Returns', 'returns_view_returns'],
    ['sc-dashboard', Dashboard, 'SC dashboard', 'sc_dashboard_view_sc_dashboard'],
    ['customers', Users, 'customers', 'customers_view_customers'],
    ['suppliers', OrdersTruck, 'suppliers/manufact..', 'suppliers_view_suppliers'],
    ['inventory', Inventory, 'inventory', 'inventory_view_inventory'],
    ['back-order', BackOrderIcon, 'back order'],
    ['sales', Sales, 'sales', 'sales_view_sales'],
    // ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
    ['stock-count-report', ScReport, 'stock count report', 'stock_count_report_view_stock_count_report'],
    ['therapeutic-types', ProductType, 'product types', 'product_type_view_product_type'],
    ['roles', Setting, 'Roles', 'role_permissions_view_role_permissions'],
    ['downloads', ScReport, 'Downloads'],
    ['cm-dashboard', Dashboard, 'CM dashboard', 'cm_dashboard_view_cm_dashboard'],
  ];
  const CHAIN_MANAGER_SIDE_ITEMS_OFFLINE = [
    ['affiliate-dashboard', Dashboard, 'affiliate dashboard', 'affiliate_dashboard_view_affiliate_dashboard'],
    ['return', Returns, 'Returns', 'returns_view_returns'],
    ['sc-dashboard', Dashboard, 'SC dashboard', 'sc_dashboard_view_sc_dashboard'],
    ['customers', Users, 'customers', 'customers_view_customers'],
    ['suppliers', OrdersTruck, 'suppliers/manufact..', 'suppliers_view_suppliers'],
    ['inventory', Inventory, 'inventory', 'inventory_view_inventory'],
    ['back-order', BackOrderIcon, 'back order'],
    ['sales', Sales, 'sales', 'sales_view_sales'],
    // ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
    ['stock-count-report', ScReport, 'stock count report', 'stock_count_report_view_stock_count_report'],
    // ['therapeutic-types', ProductType, 'product types', 'product_type_view_product_type'],
    ['roles', Setting, 'Roles', 'role_permissions_view_role_permissions'],
    ['cm-dashboard', Dashboard, 'CM dashboard', 'cm_dashboard_view_cm_dashboard'],
    ['downloads', ScReport, 'Downloads']
  ];

  const sideItems = () => {
    if (isBusinessVirtualStore) {
      return VIRTUAL_STORE_SIDE_ITEMS;
    }
    if (raspEnv && role === 'store-manager') {
      return STORE_MANAGER_SIDE_ITEMS_OFFLINE;
    }
    if (raspEnv && role === 'chain-manager') {
      return CHAIN_MANAGER_SIDE_ITEMS_OFFLINE;
    }
    switch (role) {
      case 'oga-pharmacy-admin':
        return OGA_PHARMACY_ADMIN_SIDE_ITEMS;
      case 'supplier-admin':
        return SUPPLIER_SIDE_ITEMS;
      case 'store-manager':
        return STORE_MANAGER_SIDE_ITEMS;
      case 'chain-manager':
        return CHAIN_MANAGER_SIDE_ITEMS;
      case 'affiliate-admin':
        return AFFILIATE_ADMIN_SIDE_ITEMS;
      default:
        return AFFILIATE_SIDE_ITEMS;
    }
  };

  const { data: notificationsData, refetch } = useQuery(ERP_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageCount: 10,
      pageNumber: 1,
    }
  });
  const { erpNotifications, erpNotificationsTotalNumber } = notificationsData ?? [];
  useEffect(() => {
    Subscriber.report('NotificationCount', erpNotificationsTotalNumber);
    dispatch({
      type: NotificationsActionTypes.UPDATE_NOTIFICATION_COUNT,
      payload: {
        count: erpNotificationsTotalNumber,
      }
    });
  }, [erpNotifications, erpNotificationsTotalNumber]);


  return (
    <>
      <NavbarContainer theme={useTheme()} open={sideNavOpen}>
        <Grid item container md={4} xs={platform === 'erp' ? 1 : 6} alignItems="center">
          <NavLeftMenu item container>
            <HeaderMenuButton className="navigation-hamburger" onClick={toggleSideNav}>
              <Toggle style={{ fontSize: isSmallScreen ? '5rem' : '1.8rem', marginRight: '5px' }} />
            </HeaderMenuButton>
            {platform === 'erp'
              ? (
                <WarehouseLg />
              ) : (
                <OgaAdminLogo fontSize="3rem" />
              )}
          </NavLeftMenu>
          { role !== 'affiliate-admin' && (
            <Hidden mdDown>
              <DateTimeTypo>
                {dateState.toLocaleString('en-US', {
                  weekday: 'short',
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true
                })}
              </DateTimeTypo>
            </Hidden>
          )}
        </Grid>
        <NavRightMenu item container md={8} xs={platform === 'erp' ? 11 : 6}>
          {platform === 'erp'
            ? (
              <>
                <NavItem item className="joy-cart-link">
                  <HeaderIcon onClick={() => setOpenTillDialogue(true)}>
                    <BoxStore style={{ fontSize: '2.05rem' }} />
                    <ViewCartTypo style={{ width: isSmallScreen ? '15rem' : '100%', paddingTop: isSmallScreen && businessName.length > 24 ? '2rem' : '0' }}>
                      {businessName}
                    </ViewCartTypo>
                  </HeaderIcon>
                </NavItem>
                <NavItem item className="joy-cart-link">
                  <HeaderIcon onClick={() => setOpenTillDialogue(true)}>
                    <Timer style={{ fontSize: '2.05rem' }} />
                    <ViewCartTypo>{CloseShift}</ViewCartTypo>
                  </HeaderIcon>
                </NavItem>
              </>
            ) : ''}
          <NavItem item className="joy-cart-link">
            <HeaderIcon
              onClick={() => navigate(manualOrder ? '/manual-cart' : '/cart', { state: { prevLocation: location.pathname } })}
              className="navigation-view-cart"
            >
              <SimpleBadge value={cartCounter}>
                <CartIco style={{ fontSize: isSmallScreen ? '3rem' : '2.05rem' }} />
              </SimpleBadge>
              <Hidden mdDown>
                <ViewCartTypo>View Cart</ViewCartTypo>
              </Hidden>
            </HeaderIcon>
          </NavItem>
          <AffiliatesLogo platform={platform} item container>
            <BellButton data-testid="notificationModal" onClick={handleBellClick}>
              <SimpleBadge value={count}>
                <BellIco style={{ fontSize: isSmallScreen ? '3rem' : '2.05rem' }} />
              </SimpleBadge>
            </BellButton>

            <LogoIcon onClick={handleAffiliateLogoClick}>
              <AffiliateImage alt="affiliates logo" src={affiliateLogo} />
              <Hidden mdDown>
                <ProfileTypo>{loggedInUser}</ProfileTypo>
                <DropDownArrow />
              </Hidden>
            </LogoIcon>
          </AffiliatesLogo>
        </NavRightMenu>
      </NavbarContainer>

      <DrawerContainer variant="persistent" anchor="left" open={sideNavOpen}>
        <SideNavHeader item container>
          <Hidden mdDown>
            <HeaderMenuButton style={{ marginLeft: '.7rem' }} className="navigation-hamburger" onClick={toggleSideNav}>
              <MenuToggle style={{ fontSize: '2.05rem' }} />
            </HeaderMenuButton>
            {platform === 'erp' ? (
              <WHLogo />
            ) : (
              <MpLogo />
            )}
          </Hidden>
          <Hidden mdUp>
            <CloseMenuButton container>
              <CloseIconBlue onClick={toggleSideNav} style={{ fontSize: '4rem', marginLeft: 'auto' }} />
            </CloseMenuButton>

            <SideLogoIcon container>
              <SideAffiliateImage alt="affiliates logo" src={affiliateLogo} />
              <SideProfileTypo>{loggedInUser}</SideProfileTypo>
            </SideLogoIcon>
          </Hidden>
        </SideNavHeader>
        <SideNavItems>
          {sideItems().map((sideItem) => (
            sideItem.length < 4 ? (
              <SideNavItem key={sideItem} sideItem={sideItem} handleSideClick={handleSideClick} />
            ) : userPermissions?.includes(sideItem[3]) && (
              <SideNavItem key={sideItem} sideItem={sideItem} handleSideClick={handleSideClick} />
            )
          ))}
        </SideNavItems>
      </DrawerContainer>

      <AffiliatesPopper
        affiliatesLogoEl={affiliatesLogoEl}
        handleAffiliateLogoClick={handleAffiliateLogoClick}
      />
      <NotificationsPopper
        notificationsEl={notificationsEl}
        handleBellClick={handleBellClick}
        businessUserId={businessUserId}
        notifications={erpNotifications}
        refetch={refetch}
      />
      {/* <RemoveDataDialog
        open={deleteDataOpen}
        setOpen={setDeleteDataOpen}
        deleteLocalData={deleteLocalData}
      /> */}

      <InfoTillDialogue
        dialogOpen={openTillDialogue}
        closeDialog={() => setOpenTillDialogue(false)}
      />
    </>
  );
};

export default NavigationContainer;
