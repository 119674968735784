import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NextIcon, PreviousIcon } from '../../../assets/svgs';

export const ProductCardsContainer = styled(Grid)`
display: flex;
overflow-x: hidden;
scrollBehavior: smooth;
&::-webkit-scrollbar: {
  display: none;
}
`;
export const HeaderContainer = styled(Grid)`
  justify-content: space-between;
`;

export const ButtonWrapper = styled(Grid)`
  display: flex;
  width: 5rem;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 991px) {
    width: 8rem;
  }

`;

export const ProductCardGrid = styled(Grid)`
  margin-right: 10px;
  flex: 0 0 auto;
  width: 100%;
  @media (min-width: 992px) {
    margin-right: 10px;
    width: 16.4rem;
  }
  @media (min-width: 1500px) {
    margin-right: 10px;
    width: 18.9rem;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: center;
  margin-bottom: 30px;

  @media(min-width: 992px) {
    justify-content: flex-end;
  }
`;

export const Loading = styled(Typography)`
  font-size: 20px;
  color: #4D4F5C;
  padding: 2rem;

  @media(max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const TextHeader = styled(Typography)`
  font-size: 27px;
  color: #303030;
  padding: 20px 0px;
  font-weight: 600;

  @media(max-width: 991px) {
    font-size: 3.2rem;
  }
`;

export const SimilarProductsBox = styled(Grid)`
  justify-content: center;
  margin-top: 50px;
  position: relative;
  overflowX: auto;

`;

export const NextImage = styled(NextIcon)`
  cursor: pointer;

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
      width: 50%;
      height: 60%;
    }
  }
`;

export const PrevImage = styled(PreviousIcon)`
  cursor: pointer;
  padding: 0px 3px;
  @media (max-width: 991px) {
    padding: 0px 10px;
    &.MuiSvgIcon-root {
      width: 50%;
      height: 60%;
    }
  }
`;
